import React, { useEffect, useRef, useState } from 'react';
import cookies from 'js-cookie';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import { useTranslation } from 'react-i18next';
// import Clients from './components/Clients';
// import Testimonial from './components/Testimonial';
import Info from './components/Info';
import ImgView from './components/ImgView';
import Footer from './components/Footer';
// import ChatwootWidget from './components/ChatwootWidget';
import ContactusForm from './components/contactusForm.jsx';
import HomepageDialog from './components/homepageDialog';
import { useHistory } from 'react-router-dom';
import GetNotifiedApp from './components/getNotifiedApp';

function Homepage() {
  const history = useHistory();

  const { t } = useTranslation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const [contactUsPanelVisible, setContactUsPanelVisible] = useState(false);
  const [panelActionCount, setPanelActionCount] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(false);
  const [isHomepageDialogOpen, setHomePageDialogOpen] = useState(false);
  const [trackHomepageDialog, setTrackHomepageDialog] = useState(false);
  const [dialogActionCount, setDialogActionCount] = useState(0);

  const currentLanguageCode = cookies.get('i18next');

  const myRef = useRef(null);

  const clientRef = useRef(null);

  const executeScroll = () => scrollToRef(myRef);

  const executeScrolltoClient = () => scrollToRef(clientRef);

  // scroll effect to features
  useEffect(() => {
    if (window.location.pathname.includes('features')) {
      executeScroll();
    } else if (window.location.pathname.includes('client')) {
      executeScrolltoClient();
    }
  });

  // know more about us page auto open code
  useEffect(() => {
    if (history.action === 'POP') {
      if (scrollHeight && panelActionCount === 0) {
        setContactUsPanelVisible(true);
      } else if (
        trackHomepageDialog &&
        !contactUsPanelVisible &&
        dialogActionCount === 0
      ) {
        setHomePageDialogOpen(true);
      }
    }
  }, [
    history.action,
    scrollHeight,
    panelActionCount,
    contactUsPanelVisible,
    trackHomepageDialog,
    dialogActionCount,
  ]);

  return (
    <div className='relative'>
      {/* contact us button on landing page code start */}
      <div className={`${currentLanguageCode === 'ar' ? 'left-0' : 'right-0'} absolute h-full`}>
        <div
          className={`bg-purple h-32 w-10 sticky inset-40% right-0 z-50  cursor-pointer ${currentLanguageCode === 'ar' ? "rounded-r-lg" : 'rounded-l-lg'}`}
          onClick={() => setContactUsPanelVisible(true)}
        >
          <p className={`${currentLanguageCode === 'ar' ? "translate-y-8" : 'translate-y-20'} whitespace-nowrap transform -rotate-90  pl-2 text-20px text-white font-bold font-lato select-none`}>
            {t('talk_to_us')}
          </p>
        </div>
      </div>
      {/* contact us button on landing page code end */}

      <div className='min-h-full max-w-full overflow-hidden'>
        <Navbar featureRef={executeScroll} clientRef={executeScrolltoClient} />
        <Hero />
      </div>
      <div ref={myRef}>
        <Services />
      </div>
      {/* <Clients /> */}
      <Info />
      <div ref={clientRef}>
        {/* <Testimonial /> */}
      </div>
      <ImgView />
      <GetNotifiedApp />
      <Footer featureRef={executeScroll} />
      {/* <ChatwootWidget /> */}

      {/* contactus form component for landing page */}
      <ContactusForm
        contactUsPanelVisible={contactUsPanelVisible}
        setContactUsPanelVisible={setContactUsPanelVisible}
        setPanelActionCount={setPanelActionCount}
        setScrollHeight={setScrollHeight}
      />

      {/* home page modal */}
      <HomepageDialog
        isHomepageDialogOpen={isHomepageDialogOpen}
        setHomePageDialogOpen={setHomePageDialogOpen}
        setTrackHomepageDialog={setTrackHomepageDialog}
        setDialogActionCount={setDialogActionCount}
      />
    </div>
  );
}

export default Homepage;
