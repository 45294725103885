import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
// import OwnImage from './Image';

import InProgress from '../../../assets/images/InProgress.png';
import InProgress_ar from '../../../assets/images/InProgress_ar.png';

const ImgView = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next');

  // const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  // const [ismobile, setismobile] = useState(false);

  // const [istablet, settablet] = useState(false);

  // const [size, setsize] = useState(1000);

  // const checkview = () => {
  //   if (window.innerWidth < 800) {
  //     setsize(400);
  //   } else if (window.innerWidth > 800 && window.innerWidth < 1200) {
  //     setsize(750);
  //   } else {
  //     setsize(1000);
  //   }
  // };

  // useEffect(() => {
  //   checkview();

  //   window.addEventListener('resize', checkview);
  // }, []);

  return (
    <div className='bg-white md:pt-20'>
      <div className='bg-white text-center text-2xl md:text-3xl mb-5'>
        <h4 className='font-bold leading-10 px-4 md:px-0 text-midnightBlue'>
          {' '}
          <span className='text-rose '>
            {t('homepage_seamlessly_progress')}
          </span>{' '}
          {t('homepage_candidate_from_one_stage_to_another')}
        </h4>
      </div>
      <div className='flex bg-lightblue w-full'>
        <div className='flex w-5/6 flex-col justify-center items-center mx-auto'>
          <div className='w-9/12 flex justify-center   md:mx-auto  '>
            <img src={currentLanguageCode === 'ar' ? InProgress_ar : InProgress} alt='Smart Hire Dashboard' />
            {/* <OwnImage
            src='/images/in progress.png'
            layout='fill'
            width={size}
            alt=''
          /> */}
          </div>
          <div className='text-center pt-5 pb-12'>
            <button
              className='text-purple border-2 border-purple rounded-md py-2 px-8'
              // onClick={onOpenModal}
            >
              {t('how_it_works')}?
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={onCloseModal}
        styles={{
          modal: {
            maxWidth: 'unset',
            width: '50%',
            padding: 'unset',
          },
          overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
          },
          closeButton: {
            background: '#FF009C',
            color: 'white',
          },
        }}
        center
      >
        <ReactPlayer
          url='https://youtu.be/ibacfgwXfbg'
          playing={open}
          width='100%'
          height='calc(100vh - 100px)'
        />
      </Modal>
    </div>
  );
};

export default ImgView;
