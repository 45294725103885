import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';
import { useWindowScroll } from 'react-use';
import cookies from 'js-cookie';

import { ReactComponent as CrossIcon } from '../../../assets/images/crossIcon.svg';
import { ReactComponent as InfoIlls } from '../../../assets/images/infoIlls.svg';
import Loader from './Loader';

const ContactusForm = ({
  contactUsPanelVisible,
  setContactUsPanelVisible,
  setPanelActionCount,
  setScrollHeight,
}) => {
  const { y } = useWindowScroll();
  const currentLanguageCode = cookies.get('i18next');

  useEffect(() => {
    if (y > 700 && y < 800) {
      setScrollHeight(true);
    }
  }, [setScrollHeight, y]);

  const [isFormSubmissionLoading, setIsFormSubmissionLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const defaultValues = {
    name: '',
    company_name: '',
    role: '',
    email: '',
    contact_number: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    setIsFormSubmissionLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, is_contact_enabled: true }),
    };

    fetch(
      'https://api.smart-hire.ai/Profile/KnowMoreAboutUsAPI/',
      requestOptions
    )
      .then((res) => {
        setIsFormSubmissionLoading(false);
        return res.json();
      })
      .then((res) => {
        setSuccessMessage(res.message);
        setTimeout(() => {
          setContactUsPanelVisible(false);
          setSuccessMessage();
        }, 2000);
        reset();
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setIsFormSubmissionLoading(false);
        setTimeout(() => {
          setContactUsPanelVisible(false);
          setErrorMessage();
        }, 2000);
        reset();
      });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-red-600 ml-1 text-sm'>
          {errors[name].message}
        </small>
      )
    );
  };

  return (
    <div className='notification_overlay_container'>
      <Transition.Root show={contactUsPanelVisible} as={Fragment}>
        <Dialog
          as='div'
          auto-reopen='true'
          className='fixed inset-0 '
          style={{ zIndex: '2000' }}
          onClose={setContactUsPanelVisible}
        >
          <div className='absolute inset-0 '>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 transition-opacity notification-panel-overlay-bg' />
            </Transition.Child>
            <div
              className={`fixed inset-y-0  max-w-full flex ${
                currentLanguageCode === 'ar' ? 'left-0 pr-10' : 'right-0 pl-10'
              }`}
            >
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom={
                  currentLanguageCode === 'ar'
                    ? '-translate-x-full'
                    : 'translate-x-full'
                }
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo={
                  currentLanguageCode === 'ar'
                    ? '-translate-x-full'
                    : 'translate-x-full'
                }
              >
                <div className='relative w-screen max-w-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute -top-2 right-0 pt-4 pr-2 flex sm:pr-4'>
                      <button
                        className='rounded-md focus:outline-none'
                        onClick={() => {
                          setContactUsPanelVisible(false);
                          setPanelActionCount(1);
                        }}
                      >
                        <span className='sr-only'>Close panel</span>
                        <CrossIcon className='h-5 w-5 m-4' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='h-full pt-12 bg-white shadow-xl'>
                    <p className='text-center text-xl md:text-20px font-bold text-midnightBlue'>
                      Know more about Smart Hire
                    </p>
                    <div className='h-80vh overflow-y-scroll scroller'>
                      <div className='w-9/12 mx-auto pt-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className='w-full my-2'>
                            <label htmlFor='name' className='text-C8E8E8E pl-1'>
                              Full Name *
                            </label>
                            <Controller
                              name='name'
                              control={control}
                              rules={{
                                required: 'This is a required field',
                              }}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  className='py-1.5 px-0 md:px-2 w-full customInput my-0.5'
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage('name')}
                          </div>
                          <div className='w-full mb-2'>
                            <label
                              htmlFor='company_name'
                              className='text-C8E8E8E pl-1'
                            >
                              Company *
                            </label>
                            <Controller
                              name='company_name'
                              control={control}
                              rules={{
                                required: 'This is a required field',
                              }}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  className='py-1.5 px-0 md:px-2 w-full customInput my-0.5'
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage('company_name')}
                          </div>
                          <div className='w-full mb-2'>
                            <label htmlFor='role' className='text-C8E8E8E pl-1'>
                              Role
                            </label>
                            <Controller
                              name='role'
                              control={control}
                              rules={{
                                required: 'This is a required field',
                              }}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  className='py-1.5 px-0 md:px-2 w-full customInput my-0.5'
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage('role')}
                          </div>
                          <div className='w-full mb-2'>
                            <label
                              htmlFor='email'
                              className='text-C8E8E8E pl-1'
                            >
                              Email*
                            </label>
                            <Controller
                              name='email'
                              control={control}
                              rules={{
                                required: 'This is a required field',
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: 'Invalid email address.',
                                },
                              }}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  className='py-1.5 px-0 md:px-2 w-full customInput my-0.5'
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage('email')}
                          </div>
                          <div className='w-full mb-2'>
                            <label
                              htmlFor='contact_number'
                              className='text-C8E8E8E pl-1'
                            >
                              Contact*
                            </label>
                            <Controller
                              name='contact_number'
                              control={control}
                              rules={{
                                required: 'This is a required field',
                                pattern: {
                                  value: /^[0-9]*$/i,
                                  message: 'Only numbers allowed.',
                                },
                              }}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  className='py-1.5 px-0 md:px-2 w-full customInput my-0.5'
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage('contact_number')}
                          </div>
                          <div className='flex pt-2 '>
                            <div className='pt-1.5'>
                              <InfoIlls className='w-3 h-3' />
                            </div>
                            <p className='text-15px ml-1 text-C8E8E8E pb-4'>
                              Yes I would like Smart Hire team to contact me on
                              the information provided above.{' '}
                            </p>
                          </div>
                          {successMessage ? (
                            <p className='text-sm text-center pb-2 text-green-600'>
                              {successMessage}
                            </p>
                          ) : null}
                          {errorMessage ? (
                            <p className='text-sm text-center pb-2 text-red-600'>
                              {errorMessage}
                            </p>
                          ) : null}
                          <div className='w-full flex justify-center'>
                            <button
                              className={`${
                                isFormSubmissionLoading ? 'pl-8' : ''
                              } w-24 border py-2 rounded-lg bg-purple text-white flex justify-center items-center`}
                            >
                              {isFormSubmissionLoading ? <Loader /> : 'Submit'}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ContactusForm;
