import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import 'react-responsive-modal/styles.css';
import { useWindowSize } from 'react-use';
import { ReactComponent as CheckedIlls } from '../../../assets/images/checkedIlls.svg';
import { ReactComponent as DialogImage } from '../../../assets/images/dialogImage.svg';

export default function HomepageDialog({
  isHomepageDialogOpen,
  setHomePageDialogOpen,
  setTrackHomepageDialog,
  setDialogActionCount,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setTrackHomepageDialog(true);
    }, 12000);
  }, [setTrackHomepageDialog]);
  const { width } = useWindowSize();

  const handleDialogClose = () => {
    setHomePageDialogOpen(false);
    setDialogActionCount(1);
  };

  return (
    <div className='h-full'>
      <Modal
        open={isHomepageDialogOpen}
        onClose={() => handleDialogClose()}
        center
        focusTrapped={false}
        styles={{
          modal: {
            maxWidth: 'unset',
            width: `${
              width < 800 ? '85%' : width > 800 && width < 1050 ? '55%' : '40%'
            }`,
            height: `${width < 700 ? '70%' : '55%'}`,
            boxShadow: 'none',
            borderRadius: '10px',
          },
        }}
      >
        <div className='md:pl-8 pt-6 h-full'>
          <p className='h-1/6 font-bold text-20px md:text-22px text-midnightBlue'>
            {currentLanguageCode === 'ar' ? (
              <span>
                كيف سوف يساعد <span className='text-rose'> Smart Hire </span>في
                تطوير إجراءات التوظيف في منشأتك ؟
              </span>
            ) : (
              'Do you know that Smart Hire can help you,'
            )}
          </p>
          <div className='flex flex-wrap-reverse md:h-4/6'>
            <div className='md:w-3/5 space-y-3 md:space-y-4 pt-4 md:pt-8 text-434343'>
              <div className='flex items-center'>
                <CheckedIlls />
                {currentLanguageCode === 'ar' ? (
                  <p className='pr-3 text-xl leading-5 md:leading-normal'>
                    توفير<span className='text-rose'>65%</span> من وقت المقابلة
                  </p>
                ) : (
                  <p className='pl-3 leading-5 md:leading-normal'>
                    Save <span className='text-rose'>65%</span> of your
                    interview time
                  </p>
                )}
              </div>
              <div className='flex items-center'>
                <span>

                <CheckedIlls />
                </span>
                {currentLanguageCode === 'ar' ? (
                  <p
                    className={`pr-3 text-xl  leading-5 md:leading-normal ${
                      currentLanguageCode === 'ar' ? 'text-right' : ''
                    }`}
                  >
                    <span className='text-rose'>إجراءات توظيف ذكية</span>
                    مدعومة بتقنيات الذكاء االصطناعي
                  </p>
                ) : (
                  <p className='pl-3 leading-5 md:leading-normal'>
                    Hire <span className='text-rose'>cultural fit</span> the
                    intelligent way
                  </p>
                )}
              </div>
              <div className='flex items-center'>
                <CheckedIlls />
                {currentLanguageCode === 'ar' ? (
                  <p className='pr-3 text-xl leading-5 md:leading-normal'>
                    <span className='text-rose'> إتخاذ قرارات مدروسة</span> في
                    التوظيف
                  </p>
                ) : (
                  <p className='pl-3 leading-5 md:leading-normal'>
                    Make <span className='text-rose'>informed hiring</span>{' '}
                    decisions
                  </p>
                )}
              </div>
            </div>
            <div className='md:w-2/5'>
              <DialogImage className='w-1/2 mx-auto md:mx-0 md:w-full md:h-40' />
            </div>
          </div>
          <div className='flex items-center md:h-1/6 mx-2 md:mx-4 justify-center md:mr-4 md:pb-6 mt-8 md:mt-0'>
            <Link to='/demo'>
              <button
                className='w-28 md:w-32 border py-2.5  mx-2 text-sm rounded-md border-purple text-purple font-medium'
                onClick={() => handleDialogClose()}
              >
                {t('popup_schedule_a_demo')}
              </button>
            </Link>
            <a
              href='https://app.smart-hire.ai/auth/register?trial=true'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                className='w-28 md:w-32 border py-2.5 mx-2 text-sm rounded-md border-purple bg-purple text-white font-medium'
                // onClick={() => handleDialogClose()}
              >
                {t('popup_lets_try_for_free')}
              </button>
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}
