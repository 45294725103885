import React from 'react';
import Typewriter from 'typewriter-effect';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as UnderlineZigLine } from '../../../assets/images/UnderlineZigLine.svg';
import { ReactComponent as HeroRight } from '../../../assets/images/HeroRight2.svg';

const Hero = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next');

  return (
    <div className={`${currentLanguageCode === 'ar' ? 'hero-bg-ar' : 'hero-bg'}  flex flex-col justify-center z-10`}>
      <div className='hero-content w-screen p-12 mt-6 md:mt-0'>
        <div className='block md:grid grid-cols-2 gap-12 md:mt-0'>
          <div
            className={`text col-span-2 md:col-span-1 text-light flex flex-col justify-center items-start  md:mt-20  lg:mt-16 ${
              currentLanguageCode === 'ar'
                ? 'pr-0 md:pr-4 pr-0'
                : 'pl-0 md:pl-8 lg:pl-12'
            }`}
          >
            <h1
              className={`text-center ${
                currentLanguageCode === 'ar' ? 'md:text-right' : 'md:text-left'
              }`}
            >
              <span className='mx-auto md:mx-0 text-4xl mt-0 md:-mt-16 text-left md:text-4xl lg:text-5xl xl:text-7xl font-bold whitespace-nowrap hidden md:block'>
                <span className='text-gradient2'>
                  {`${t('homepage_hero_reinvent')} ${
                    currentLanguageCode === 'ar'
                      ? t('homepage_hero_your_hiring')
                      : ''
                  }`}
                </span>
                {currentLanguageCode === 'ar' ? '' : 'your hiring'}
              </span>
              <br />
              <span className='mx-auto font-normal md:mx-0 text-2xl  mt-8 md:mt-5 text-center md:text-left'>
                <span className='md:hidden text-white'>
                  <span className='text-gradient'>
                    {`${t('homepage_hero_reinvent')} ${
                      currentLanguageCode === 'ar'
                        ? t('homepage_hero_your_hiring')
                        : ''
                    }`}
                  </span>
                  {currentLanguageCode === 'ar' ? '' : 'your hiring'}
                </span>
                <br className=' md:hidden' />
                <span className='text-white'>
                  {t('homepage_hero_header_2')}
                </span>{' '}
                <br className=' md:hidden' />
                {currentLanguageCode === 'ar' ? (
                  ''
                ) : (
                  <span className='text-rose '>
                    CV Screening <span className='text-white'> and</span>{' '}
                    Psychometrics{' '}
                  </span>
                )}
                {/* <span className="text-white">&amp;</span> <span className="text-orange"><br/> Psychometrics  </span> */}
              </span>
            </h1>
            <span className='relative mx-auto md:w-96 lg:mx-0 lg:top-0 mt-8 md:mt-12 text-2xl font-bold inline-flex text-white'>
              {t('homepage_hero_look_for_the_right')}{' '}
              <span className=' text_slide text-rose ml-2 mr-2 relative bottom-2'>
                <Typewriter
                  options={{
                    strings:
                      currentLanguageCode === 'ar'
                        ? ['الشخصيات', 'المهارات', 'الخبرات']
                        : ['Personality', 'Skill', 'Experience'],
                    autoStart: true,
                    loop: true,
                  }}
                />
                <UnderlineZigLine />
              </span>
            </span>
          </div>
          <div className='bottom-4 right-8 md:right-0 md:bottom-10 lg:ml-5 w-full mx-auto grid place-items-center lg:mb-12 md:pb-0'>
            <HeroRight className='w-10/12' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
