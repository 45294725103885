import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
// import SampleThumbnail from '../../../assets/images/SampleThumbnail.png';
import UserProfileImage from '../../../assets/images/UserProfileImage.webp';

// import { ReactComponent as YoutubeIcon } from '../../../assets/images/YoutubeIcon.svg';
// import { ReactComponent as TwiterIcon } from '../../../assets/images/TwiterIcon.svg';
// import { ReactComponent as LinkedInIcon } from '../../../assets/images/LinkedInIcon.svg';
import Loader from '../../Homepage/components/Loader';
import Avatar from './Avatar';
import dayjs from 'dayjs';

function BlogSmallCard({ blog }) {
  return (
    <Link to={`/blog/${blog.slug}`}>
      <div className='flex w-full my-6 xl:mx-auto cursor-pointer'>
        <div className='flex w-24 h-15 relative mt-1'>
          <img src={blog?.thumbnail} alt={blog?.title} className='w-full ' />
          <span className='absolute flex w-full h-full bg-black bg-opacity-40'></span>
        </div>
        <div className='flex flex-col w-72 pl-2'>
          <div className=''>
            <p className='text-838282 text-md leading-5 font-semibold font-Raleway'>
              {blog?.title?.substring(0, 50) +
                `${blog?.title?.length > 50 ? '...' : ''}`}
            </p>
          </div>
          <div>
            <p className='text-838282 text-sm font-medium font-Raleway'>
              {blog?.minutes} min read
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

function SingleBlog() {
  const { slugID } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [moreBlogsData, setMoreBlogsData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreBlogs, setIsLoadingMoreBlogs] = useState(false);

  const blogsData = async (slugID) => {
    setIsLoading(true);
    // POST request using fetch with set headers
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await fetch(
      `https://blog-api.apta-hr.com/posts?slug=${slugID}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        setBlogData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('error: ' + error);
        setIsLoading(false);
      });
  };

  const moreBlogsDataFn = async (slugID) => {
    setIsLoadingMoreBlogs(true);
    // POST request using fetch with set headers
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await fetch(
      `https://blog-api.apta-hr.com/post-top-five?slug=${slugID}&for_domain=https://www.apta-hr.com`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        setMoreBlogsData(data);
        setIsLoadingMoreBlogs(false);
      })
      .catch((error) => {
        console.log('error: ' + error);
        setIsLoadingMoreBlogs(false);
      });
  };

  useEffect(() => {
    blogsData(slugID);
    moreBlogsDataFn(slugID);
    // eslint-disable-next-line
  }, [slugID]);

  return (
    <div className='flex flex-col w-full font-Raleway mb-20'>
      <div className='flex justify-start'>
        <p className='font-medium mt-14 px-4 xl:px-0 py-6 text-B3B3B3'>
          <Link to='/blog'>Blogs </Link> {'<'}{' '}
          <span className='text-838282 font-semibold'>Blogs by AptaHR</span>{' '}
        </p>
      </div>
      <div className='flex flex-col xl:flex-row xl:mx-2 2xl:mx-0 w-full mt-10'>
        <div className='flex w-full xl:w-9/12 px-4 xl:px-6 xl:pl-2 2xl:pl-0 overflow-hidden'>
          {isLoading ? (
            <div className=' rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
              <Loader width='70' height='70' color='#FF009C' />
            </div>
          ) : (
            <>
              {blogData?.data?.length ? (
                blogData?.data?.map((blog) => (
                  <div key={blog.id}>
                    <div
                      className='w-full max-w-full htmlContent '
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(blog.content),
                      }}
                    ></div>
                    <div className='flex w-full  mt-20'>
                      <div className='flex'>
                        <Avatar
                          imgSrc={blog?.profile_picture ?? UserProfileImage}
                          className='inline object-cover w-10 h-10 rounded-full'
                          userInitials={`${
                            'A'
                            // blog?.name
                            //   ? blog?.name.charAt(0).toUpperCase()
                            //   : blog?.first_name.charAt(0).toUpperCase()
                          }`}
                        />
                      </div>
                      <div className='flex flex-col ml-2'>
                        <p className='font-semibold'>{blog?.user_name}</p>
                        <p className='text-greyLight text-sm font-medium'>
                          {dayjs(blog?.create_date).format('DD MMM YYYY')}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className=' rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
                  <p>No content!</p>
                </div>
              )}
            </>
          )}
        </div>

        <div className='flex flex-col justify-between w-full xl:w-3/12 mt-20 xl:mt-0 px-4 xl:pl-6 xl:pr-0  xl:border-l border-C9C9C9'>
          <div className='h-[44rem] max-h-[44rem] xl:sticky xl:top-0 xl:left-0 pt-4'>
            <div>
              <p className='flex font-medium text-xl mb-8'>
                More blogs to read
              </p>
              {isLoadingMoreBlogs ? (
                <div className=' rounded-xl max-w-screen-xl w-full mx-auto min-h-30vh flex justify-center items-center'>
                  <Loader width='70' height='70' color='#FF009C' />
                </div>
              ) : (
                <>
                  {moreBlogsData?.data?.length ? (
                    moreBlogsData?.data?.map((blog) => (
                      <div key={blog.id}>
                        <BlogSmallCard blog={blog} />
                      </div>
                    ))
                  ) : (
                    <div className=' rounded-xl max-w-screen-xl w-full mx-auto min-h-30vh flex justify-center items-center'>
                      <p>No blog!</p>
                    </div>
                  )}
                </>
              )}
            </div>
            {/* <div className='flex flex-col mt-20'>
              <div className='flex justify-between pb-6 px-4 border-b border-C9C9C9 text-md text-7D7D7D font-medium'>
                <span>Podcasts</span>
                <span>Abouts us</span>
                <span>Career</span>
                <span>Contact</span>
              </div>
              <div className='flex pt-6 px-4'>
                <span className='mx-4'>
                  <YoutubeIcon />
                </span>
                <span className='mx-4'>
                  <TwiterIcon />
                </span>
                <span className='mx-4'>
                  <LinkedInIcon />
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleBlog;
