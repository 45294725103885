import './avatar.styles.scss';

const Avatar = ({ withRing, imgSrc, userInitials, className }) => {
  if (!imgSrc) {
    return (
      <div className='avatar placeholder'>
        <div
          className={`${className} bg-gray-100 text-neutral-content rounded-full w-10 h-10 ring-1 ring-primary-orange ring-offset-base-100 ring-offset-0`}
        >
          <span className='text-lg'>{userInitials}</span>
        </div>
      </div>
    );
  }
  if (withRing) {
    return (
      <div className='avatar'>
        <div
          className={`${className} rounded-full w-10 h-10 ring ring-primary-orange ring-offset-base-100 ring-offset-0`}
        >
          <img src={imgSrc} alt='User' />
        </div>
      </div>
    );
  }
  return (
    <div className='avatar'>
      <div className={`${className} rounded-full w-10 h-10`}>
        <img src={imgSrc} alt='User' />
      </div>
    </div>
  );
};

export default Avatar;
