import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import ScrollLock from 'react-scrolllock';
// import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import Group_12974 from '../../../assets/images/Group_12974.png';
import Group_12835 from '../../../assets/images/Group_12835.png';
import Group_12998 from '../../../assets/images/Group_12998.png';
import Group_13002 from '../../../assets/images/Group_13002.png';
import Group_12835_ar from '../../../assets/images/Group_12835_ar.png';
import Group_12974_ar from '../../../assets/images/Group_12974_ar.png';
import Group_12998_ar from '../../../assets/images/Group_12998_ar.png';
import Group_13002_ar from '../../../assets/images/Group_13002_ar.png';

// install Swiper modules
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle';

// import OwnImage from './Image';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

// import { CheckIcon, ChevronRightIcon } from '@heroicons/react/outline';

const Services = () => {
  const [progress, setProgress] = useState(25);
  const [section, setSection] = useState(1);
  const [lockscroll, setLockscroll] = useState(false);

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next');

  // const [imagePosition, setimagePosition] = useState('');
  // const [ContentPosition, setContentPosition] = useState('');
  const [img, setImg] = useState('Group_12974');

  // const [allow, setallow] = useState(true);

  const [ismobile, setismobile] = useState(false);

  //   const scrolling = () => {
  //     if (window.scrollY > 780 && window.scrollY < 793) {
  //       setLockscroll(true);
  //     } else {
  //       setLockscroll(false);
  //     }
  //   };

  const checkview = () => {
    if (window.innerWidth < 1200) {
      setismobile(true);
      setLockscroll(false);
    } else {
      setismobile(false);
    }
  };

  //   useEffect(() => {
  //     if (allow) {
  //       window.addEventListener('scroll', scrolling);
  //     } else {
  //       window.removeEventListener('scroll', scrolling);
  //     }
  //   }, []);

  useEffect(() => {
    checkview();
    switch (section) {
      case 1:
        setProgress(25);
        setImg(currentLanguageCode === 'ar' ? 'Group_12974_ar' : 'Group_12974');
        break;
      case 2:
        setProgress(50);
        setImg(currentLanguageCode === 'ar' ? 'Group_12835_ar' : 'Group_12835');
        break;
      case 3:
        setProgress(75);
        setImg(currentLanguageCode === 'ar' ? 'Group_12998_ar' : 'Group_12998');
        break;
      case 4:
        setProgress(100);
        setImg(currentLanguageCode === 'ar' ? 'Group_13002_ar' : 'Group_13002');
        break;
      default:
        setProgress(25);
        setImg(currentLanguageCode === 'ar' ? 'Group_12974_ar' : 'Group_12974');
    }
    window.addEventListener('resize', checkview);
  }, [section, ismobile, currentLanguageCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      var activesection = (section + 1) % 5;
      if (activesection === 0) {
        // setallow(false);
        setSection(1);

        setLockscroll(false);
      } else {
        setSection(activesection);
      }
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [lockscroll, section]);

  return (
    <ScrollLock isActive={lockscroll}>
      <div className='bg-light'>
        {!ismobile ? (
          <>
            <div
              className={`  progress h-3 `}
              style={{
                width: `${progress}%`,
                background:
                  'linear-gradient(90deg, #A200CA 17.19%, #E200AA 100%)',
              }}
            ></div>

            <div
              className={` service-content block lg:flex items-center py-16 bg-white`}
            >
              <div
                className={`${
                  currentLanguageCode === 'ar' ? 'md:mr-14 2xl:py-10' : 'md:ml-14'
                } service-points flex flex-col w-5/12 mx-auto`}
              >
                <div className={`flex my-5 ${section !== 1 ? 'ml-6' : ''}`}>
                  <div
                    className={`border-4 rounded-full ${
                      section === 1 ? 'border-rose bg-rose' : 'border-white'
                    }`}
                  ></div>
                  <div
                    className={`${
                      section === 1
                        ? 'shadow-xl py-5 px-6 rounded-md rounded-tl-none rounded-bl-none'
                        : ''
                    } cursor-pointer w-full`}
                    onClick={() => setSection(1)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        <span className='text-rose'>فحص السيرة الذاتية </span>
                        باستخدام الذكاء الاصطناعي{' '}
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        AI powered{' '}
                        <span className='text-rose'>CV screening</span>
                      </h2>
                    )}
                    <p className='text-gray-600 text-lg mt-2'>
                      {t('homepage_services_p1')}
                    </p>
                  </div>
                </div>

                <div
                  className={` hidden md:flex my-5 ${
                    section !== 2 ? 'ml-6' : ''
                  }`}
                >
                  <div
                    className={`border-4 rounded-full ${
                      section === 2 ? 'border-rose bg-rose' : 'border-white'
                    }`}
                  ></div>
                  <div
                    className={`${
                      section === 2
                        ? 'shadow-xl rounded-md py-5 px-6 rounded-tl-none rounded-bl-none'
                        : ''
                    } cursor-pointer w-full`}
                    onClick={() => setSection(2)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        <span className='text-rose'>طرق توظيف </span>
                        تلائم المتطلبات المختلفة لكل وظيفة
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        Customise{' '}
                        <span className='text-rose'>hiring workflow</span> for
                        every job
                      </h2>
                    )}
                    <p className='text-gray-600 text-lg mt-2'>
                      {t('homepage_services_p2')}
                    </p>
                  </div>
                </div>

                <div
                  className={`hidden md:flex my-5 ${
                    section !== 3 ? 'ml-6' : ''
                  }`}
                >
                  <div
                    className={`border-4 rounded-full ${
                      section === 3 ? 'border-rose bg-rose' : 'border-white'
                    }`}
                  ></div>
                  <div
                    className={`${
                      section === 3
                        ? 'shadow-xl rounded-md py-5 px-6 rounded-tl-none rounded-bl-none'
                        : ''
                    } cursor-pointer w-full`}
                    onClick={() => setSection(3)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        <span className='text-rose'>تحليل السمات الشخصية </span>
                        باستخدام الذكاء الاصطناعي
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        AI-powered{' '}
                        <span className='text-rose'>psychometrics</span>
                      </h2>
                    )}
                    <p className='text-gray-600 text-lg mt-2'>
                      {t('homepage_services_p3')}
                    </p>
                  </div>
                </div>

                <div
                  className={`hidden md:flex  my-5 ${
                    section !== 4 ? 'ml-6' : ''
                  }`}
                >
                  <div
                    className={`border-4 rounded-full ${
                      section === 4 ? 'border-rose bg-rose' : 'border-white'
                    }`}
                  ></div>
                  <div
                    className={`${
                      section === 4
                        ? 'shadow-xl rounded-md py-5 px-6 rounded-tl-none rounded-bl-none'
                        : ''
                    } cursor-pointer w-full`}
                    onClick={() => setSection(4)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        تمكين أخصائي التوظيف من إدارة
                        <span className='text-rose'>
                          {' '}
                          جميع مراحل التوظيف في منصة واحدة
                        </span>
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        Enabling hiring teams to{' '}
                        <span className='text-rose'>
                          collaborate on a <br /> single platform
                        </span>
                      </h2>
                    )}
                    <p className='text-gray-600 text-lg mt-2'>
                      {t('homepage_services_p4')}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  currentLanguageCode === 'ar' ? 'md:ml-14' : 'md:mr-14'
                } w-5/12 flex h-96  justify-center items-center`}
              >
                <div>
                  <img
                    src={
                      img === 'Group_12974'
                        ? Group_12974
                        : img === 'Group_12835'
                        ? Group_12835
                        : img === 'Group_12998'
                        ? Group_12998
                        : img === 'Group_13002'
                        ? Group_13002
                        : img === 'Group_12835_ar'
                        ? Group_12835_ar
                        : img === 'Group_12974_ar'
                        ? Group_12974_ar
                        : img === 'Group_12998_ar'
                        ? Group_12998_ar
                        : Group_13002_ar
                    }
                    alt={
                      img === 'Group_12974'
                        ? 'A hiring manager screening CVs using Smart Hire'
                        : img === 'Group_12835'
                        ? 'A hiring manager using Smart Hire to schedule interviews'
                        : img === 'Group_12998'
                        ? 'AI-powered psychometrics in Smart Hire'
                        : 'Enabling hiring teams to collaborate on Smart Hire'
                    }
                  />
                  {/* <OwnImage
                    src={`/images/${img}`}
                    layout='fill'
                    width={700}
                    alt=''
                  /> */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Swiper
              spaceBetween={50}
              id='swiper-color'
              slidesPerView={1}
              navigation={true}
              pagination={{ clickable: true }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log('slide change')}
            >
              <SwiperSlide className=' w-full text-rose '>
                <div className={`flex mb-5 mt-8 w-11/12 mx-auto `}>
                  <div
                    className={`border-4 rounded-full bg-rose  border-rose`}
                  ></div>
                  <div
                    className={`shadow-xl py-5 px-6 rounded-md rounded-tl-none rounded-bl-none cursor-pointer w-full`}
                    onClick={() => setSection(1)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        <span className='text-rose'>فحص السيرة الذاتية </span>
                        باستخدام الذكاء الاصطناعي{' '}
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        AI powered{' '}
                        <span className='text-rose'>CV screening</span>
                      </h2>
                    )}
                    <p className='text-868686 text-lg mt-2'>
                      {t('homepage_services_p1')}
                    </p>
                  </div>
                </div>
                <div
                  className={`w-4/5 mx-auto lg:mt-0  grid place-items-center `}
                >
                  <div>
                    <img
                      src={currentLanguageCode === 'ar' ? Group_12974_ar : Group_12974}
                      alt='A hiring manager screening CVs using Smart Hire”'
                    />
                    {/* <OwnImage
                      layout='fill'
                      width={360}
                      src={`/images/Group_12974`}
                      alt=''
                    /> */}
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className=' w-full '>
                <div className={`  flex my-5 w-11/12 mx-auto  `}>
                  <div
                    className={`border-4 rounded-full border-rose bg-rose`}
                  ></div>
                  <div
                    className={`shadow-xl rounded-md py-5 px-6 rounded-tl-none rounded-bl-none w-full`}
                    onClick={() => setSection(2)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        <span className='text-rose'>طرق توظيف </span>
                        تلائم المتطلبات المختلفة لكل وظيفة
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        Customise{' '}
                        <span className='text-rose'>hiring workflow</span> for
                        every job
                      </h2>
                    )}
                    <p className='text-gray-600 text-lg mt-2'>
                      {t('homepage_services_p2')}
                    </p>
                  </div>
                </div>

                <div
                  className={`w-3/5 flex   mt-1 mx-auto lg:mt-0  items-center`}
                >
                  <div>
                    <img
                      src={currentLanguageCode === 'ar' ? Group_12835_ar : Group_12835}
                      alt='A hiring manager using Smart Hire to schedule interviews'
                    />
                    {/* <OwnImage
                      layout='fill'
                      width={360}
                      src={`/images/Group_12835`}
                      alt=''
                    /> */}
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className=' w-full  h-5/6 '>
                <div className={`flex my-5 w-11/12 mx-auto `}>
                  <div
                    className={`border-4 rounded-full border-rose bg-rose`}
                  ></div>
                  <div
                    className={` shadow-xl rounded-md py-5 px-6 rounded-tl-none rounded-bl-none cursor-pointer w-full`}
                    onClick={() => setSection(3)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        <span className='text-rose'>تحليل السمات الشخصية </span>
                        باستخدام الذكاء الاصطناعي
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        AI-powered{' '}
                        <span className='text-rose'>psychometrics</span>
                      </h2>
                    )}
                    <p className='text-gray-600 text-lg mt-2'>
                      {t('homepage_services_p3')}
                    </p>
                  </div>
                </div>

                <div
                  className={`w-3/5 flex   mt-1 mx-auto lg:mt-0  items-center`}
                >
                  <div>
                    <img
                      src={currentLanguageCode === 'ar' ? Group_12998_ar : Group_12998}
                      alt='AI-powered psychometrics in Smart Hire'
                    />
                    {/* <OwnImage
                      layout='fill'
                      width={360}
                      src={`/images/Group_12998`}
                      alt=''
                    /> */}
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className='w-full py-5 '>
                <div className={` flex my-5 w-11/12 mx-auto `}>
                  <div
                    className={`border-4 rounded-full border-rose bg-rose`}
                  ></div>
                  <div
                    className={` shadow-xl rounded-md py-5 px-6 rounded-tl-none rounded-bl-none cursor-pointer w-full`}
                    onClick={() => setSection(4)}
                  >
                    {currentLanguageCode === 'ar' ? (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        تمكين أخصائي التوظيف من إدارة
                        <span className='text-rose'>
                          {' '}
                          جميع مراحل التوظيف في منصة واحدة
                        </span>
                      </h2>
                    ) : (
                      <h2 className='text-2xl font-bold text-midnightBlue'>
                        Enabling hiring teams to{' '}
                        <span className='text-rose'>
                          collaborate on a <br /> single platform
                        </span>
                      </h2>
                    )}
                    <p className='text-gray-600  text-lg mt-2'>
                      {t('homepage_services_p4')}
                    </p>
                  </div>
                </div>

                <div
                  className={`w-3/5 flex mt-1 mx-auto lg:mt-0  items-center`}
                >
                  <div>
                    <img
                      src={currentLanguageCode === 'ar' ? Group_13002_ar : Group_13002}
                      alt='Enabling hiring teams to collaborate on Smart Hire'
                    />
                    {/* <OwnImage
                      layout='fill'
                      width={360}
                      src={`/images/Group_13002`}
                      alt=''
                    /> */}
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </>
        )}
      </div>
    </ScrollLock>
  );
};

export default Services;
