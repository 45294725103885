import AppRoutes from './routes';
import { useTranslation } from 'react-i18next';

import cookies from 'js-cookie';
// import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { useEffect } from 'react';

// import microsfot_powered from './assets/microsoft_powered.png';

// const MicrosoftButton = () => {
//   return (
//     <a
//       href='https://azuremarketplace.microsoft.com/en-us/marketplace/apps/aptagrimconsultingllp1644987858185.apta-hr?tab=Overview'
//       target='_blank'
//       rel='noreferrer'
//     >
//       <img src={microsfot_powered} alt='Microsoft' className=' px-2' />
//     </a>
//   );
// };

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

function App() {
  const currentLanguageCode = cookies.get('i18next') || 'ar';
  // const currentLanguageCode = cookies.get('i18next') || 'ar';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log('Setting page stuff');
    document.body.dir = currentLanguage.dir || 'ltr';
    localStorage.setItem('dir', JSON.stringify(currentLanguage.dir || 'ltr'));
    // document.title = t('app_title');
  }, [currentLanguage, t]);

  return (
    <div
      className={`App bg-light h-full ${
        currentLanguage.code === 'ar' ? 'font-ar-baloo' : 'font-en-lato'
      }`}
    >
      <AppRoutes />
      {/* <Fab
        icon={<MicrosoftButton />}
        alwaysShowTitle={true}
        style={{ bottom: 5, left: 24 }}
        mainButtonStyles={{
          backgroundColor: 'inherit',
          borderRadius: '0',
          height: '60px',
          width: '150px',
          boxShadow: 'none',
          transform: 'none',
          transition: 'none',
        }}
        event='click'
      ></Fab> */}
    </div>
  );
}

export default App;

/* <div>
        <Navbar />
        <Hero />
      </div>
      <div id='services'>
        <Services />
      </div>
      <Clients />
      <Testimonial />
      <Info />
      <ImgView />
      <Footer />
      <ChatwootWidget /> */
