import React, { Suspense } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Loader from '../Homepage/components/Loader';
import Navbar from '../Homepage/components/Navbar';
import AllBlogs from './components/allBlogs';
import SingleBlog from './components/singleBlog';

import heroBg from '../../assets/images/BlogHeroBg.png';
import Footer from '../Homepage/components/Footer';

function Blogs() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Navbar />
      <section
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className='h-50vh flex items-center justify-center z-40 relative'
      >
        <p className='text-white w-56 md:w-full text-2xl lg:text-4xl leading-relaxed text-center font-semibold'>
          {/* <span>&#8220;</span> */}
          Great thoughts and inspirations
          <br className='hidden md:block' />
          curated just for you
          {/* <span>&#8221;</span> */}
        </p>
      </section>
      <div className='container mx-auto flex'>
        <div className='flex flex-col max-w-screen-xl w-full mx-auto'>
          <Suspense
            fallback={
              <div className='bg-white rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
                <Loader width='70' height='70' color='#FF009C' />
              </div>
            }
          >
            <Switch>
              <Redirect exact from={path} to={`${path}/all`} />
              <Route path={`${path}/all`}>
                <AllBlogs />
              </Route>
              <Route path={`${path}/:slugID`}>
                <SingleBlog />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogs;
