import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loader from '../pages/Homepage/components/Loader';
import ScrollToTop from './scrollTotop';

import Homepage from '../pages/Homepage';
import Blogs from '../pages/blogs';
// const PricingPlans = lazy(() => import('../pages/PricingPlans/PricingPlans'));
const QuoteForPlans = lazy(() =>
  import('../pages/PricingPlans/pages/quoteForPlans')
);
const PricingPlans = lazy(() => import('../pages/PricingPlans/PricingPlans'));
const PrivacyPolicy = lazy(() =>
  import('../pages/PrivacyPollicy/PrivacyPolicy')
);
const Demo = lazy(() => import('../pages/Demo/Demo'));
const Demosuccess = lazy(() => import('../pages/Demo/demosuccess'));
const Resources = lazy(() => import('../pages/Resources'));
const WebinarAI = lazy(() => import('../pages/Resources/components/WebinarAI'));
const Posidex = lazy(() => import('../pages/Resources/components/Posidex'));
const ProductsUpdate = lazy(() => import('../pages/productPage/index'));
const Tracking = lazy(() => import('../pages/tracking'));
const Contact = lazy(() => import('../pages/contact/index'));
const SuccessPage = lazy(() =>
  import('../pages/PricingPlans/pages/successPage')
);
// import PricingPlans from '../pages/PricingPlans/PricingPlans';
// import PrivacyPolicy from '../pages/PrivacyPollicy/PrivacyPolicy';
// import Demo from '../pages/Demo/Demo';
// import Demosuccess from '../pages/Demo/demosuccess';
// import Resources from '../pages/Resources';
// import Posidex from '../pages/Resources/components/Posidex';
// import WebinarAI from '../pages/Resources/components/WebinarAI';

const AppRoutes = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='bg-white rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
              <Loader width='70' height='70' color='#ff009c' />
            </div>
          }
        >
          <Switch>
            <Route path='/product-page'>
              <ProductsUpdate />
            </Route>
            <Route path='/privacy-policy'>
              <PrivacyPolicy />
            </Route>
            {/* <Route exact path='/pricing-plans/success'>
              <SuccessPage />
            </Route>
            <Route path='/pricing-plans/:planID'>
              <QuoteForPlans />
            </Route>
            <Route path='/pricing-plans'>
              <PricingPlans />
            </Route>
            <Route path='/tracking'>
              <Tracking />
            </Route> */}
            <Route exact path='/features'>
              <Homepage />
            </Route>
            <Route path='/clients'>
              <Homepage />
            </Route>
            <Route exact path='/demo'>
              <Demo />
            </Route>
            <Route path='/blog'>
              <Blogs />
            </Route>
            <Route exact path='/demo-scheduled'>
              <Demosuccess />
            </Route>
            <Route exact path='/resources/Posidex'>
              <Posidex />
            </Route>
            <Route exact path='/resources/WebinarAI'>
              <WebinarAI />
            </Route>
            <Route exact path='/resources'>
              <Resources />
            </Route>
            <Route exact path='/contact'>
              <Contact />
            </Route>
            <Route exact path='/' >
              <Homepage />
            </Route>
            {/* <Route path='/home'>
            <Homepage />
          </Route> */}
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default AppRoutes;
