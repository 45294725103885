import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as Group12218 } from '../../../assets/images/Group12218.svg';
// import { ReactComponent as Group12225 } from '../../../assets/images/Group12225.svg';
import { ReactComponent as Group12214 } from '../../../assets/images/Group12214.svg';
import { ReactComponent as Group12976 } from '../../../assets/images/Group12976.svg';
import Group11748 from '../../../assets/images/Group11748.png';
import Group11751 from '../../../assets/images/Group11751.png';
import Mobiles from '../../../assets/images/Mobiles.png';
import Mobiles_ar from '../../../assets/images/Mobiles_ar.png';
import Loader from './Loader';

function GetNotifiedApp() {
  const [email2, setEmail2] = useState('');
  const [isLoading2, setIsLoading2] = useState(false);

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next');

  const subscribeFunction2 = async (event) => {
    event?.preventDefault();
    if (email2.length > 0) {
      setIsLoading2(true);
      // POST request using fetch with set headers
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer my-token',
        },
        body: JSON.stringify({ email: email2, for_app: true }),
      };

      const response = await fetch(
        'https://app.smart-hire.ai/Profile/SubsribeAPI/',
        requestOptions
      );
      setIsLoading2(false);
      setEmail2('');
      // eslint-disable-next-line
      const data = await response.json();
      // console.log(data);
    }
  };

  return (
    <>
      {/* Section 01 */}
      <div className='bg-white pt-24 pb-24 md:pb-40 relative'>
        <div className=' block lg:flex justify-between items-center w-4/5 mx-auto md:px-10'>
          <div className='absolute left-4 top-0  md:left-14 lg:left-20 lg:top-6'>
            <Group12218 className='w-16 md:w-24' />
          </div>

          <div className='absolute right-10 lg:top-36 lg:right-32 '>
            <Group12976 className='w-14 md:w-20' />
          </div>

          <h5 className='text-2xl md:text-3xl mx-auto lg:ml-auto md:w-8/12 w-11/12 font-bold text-center text-midnightBlue'>
            {t('homepage_get_notifications_text')}
            {currentLanguageCode === 'ar' ? (
              ''
            ) : (
              <span className='text-rose'> hire?</span>
            )}
          </h5>
          <div className=' flex flex-col items-center md:items-start md:flex-row w-11/12 md:w-7/12  mt-8 lg:w-6/12 xl:w-4/12  mb-5 ml-4 md:ml-44 lg:ml-6 xl:ml-0'>
            {/* <button
              disabled
              className='border-2 border-orange opacity-40 cursor-default text-orange rounded-md py-2 md:mr-8  w-40 mb-6 md:mb-0'
            >
              Talk to us
            </button> */}
            <a
              href='https://app.smart-hire.ai/auth/register?trial=true'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='bg-purple text-white border-2 border-purple rounded-md py-2 w-40 mx-6'>
                {t('try_for_free')}
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Section 02 */}
      <div className='relative'>
        <div className='bg-white top-0 h-1/2 2xl:h-52 w-full absolute'></div>
        <div className='apps relative'>
          <div className=' w-full md:w-9/12 lg:w-8/12 flex justify-between pb-20  md:justify-evenly md:mx-auto md:px-5 '>
            <div className='flex flex-col md:flex-row w-full justify-center items-center'>
              <div className='w-8/12 md:w-6/12 flex justify-center items-end relative'>
                <img src={currentLanguageCode === 'ar' ? Mobiles_ar : Mobiles} alt='Apta HR Mobile Application' />
                <div className='absolute -left-6 top-0'>
                  <Group12976 className='w-9 md:w-16' />
                </div>
              </div>
              <div className='flex flex-col items-center md:items-start justify-center w-8/12 md:w-5/12 md:ml-8 mt-10 md:mt-20 lg:mt-10'>
                <h2 className=' text-xl md:text-2xl mt-2 text-midnightBlue'>
                  {t('get_notified_on_the_smart_hire')}
                </h2>
                <form onSubmit={subscribeFunction2}>
                  <div className='shadow-xl flex mt-8 md:mt-4 mb-8 relative w-full max-w-xs '>
                    <input
                      type='email'
                      name=''
                      value={email2}
                      className='py-2.5 px-5 w-10/12'
                      placeholder={t('your_email')}
                      onChange={(e) => {
                        setEmail2(e.target.value);
                      }}
                    />
                    <button
                      type='submit'
                      className='bg-purple w-32 text-white  px-1 ml-0.5'
                    >
                      {isLoading2 ? (
                        <span className='flex justify-center'>
                          <Loader />
                        </span>
                      ) : (
                        t('notify_me')
                      )}
                    </button>
                  </div>
                </form>
                <div className='flex w-full md:w-full flex-row md:flex-row'>
                  <img
                    src={Group11751}
                    className='mx-1 w-32 md:w-auto md:mx-2 my-2 md:my-0'
                    alt=''
                  />
                  <img
                    src={Group11748}
                    alt=''
                    className='w-32 md:w-auto my-2 md:my-0'
                  />
                </div>
              </div>
            </div>

            <div className=' absolute right-5 md:right-14 top-8'>
              <Group12214 className='w-10 md:w-20' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetNotifiedApp;
