import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/RightArrowIcon.svg';
// import SampleThumbnail from '../../../assets/images/SampleThumbnail.png';
import Loader from '../../Homepage/components/Loader';

function BlogCard({ blog }) {
  return (
    <div className='flex flex-col w-96 my-10 mx-auto'>
      <div className='flex h-54 relative'>
        <img src={blog?.thumbnail} alt={blog?.title} className='w-full ' />
        <span className='absolute flex w-full h-full bg-black bg-opacity-40'></span>
        <Link to={`${blog?.slug}`}>
          <span className='absolute right-3 bottom-3 cursor-pointer'>
            <RightArrowIcon className='w-10 h-10 2xl:w-12 2xl:h-12' />
          </span>
        </Link>
      </div>
      <div className='py-6 max-h-32'>
        <p className='text-838282 text-xl font-semibold font-Raleway'>
          {blog?.title?.substring(0, 80) +
            `${blog?.title?.length > 80 ? '...' : ''}`}
        </p>
      </div>
      <div>
        <p className='text-838282 text-xl font-medium font-Raleway'>
          {blog?.minutes} min read
        </p>
      </div>
    </div>
  );
}

function AllBlogs() {
  const [allBlogsData, setAllBlogsData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const blogsData = async () => {
    setIsLoading(true);
    // POST request using fetch with set headers
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await fetch(
      'https://blog-api.apta-hr.com/posts?for_domain=https://www.apta-hr.com',
      requestOptions
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setAllBlogsData(data);
      })
      .catch((error) => {
        console.log('error: ' + error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    blogsData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-center'>
        <p className='text-3xl 2xl:text-4xl font-bold mt-14 py-6'>Blogs</p>
      </div>
      {isLoading ? (
        <div className='bg-white rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
          <Loader width='70' height='70' color='#FF009C' />
        </div>
      ) : (
        <>
          {allBlogsData?.data?.length ? (
            <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-20'>
              {allBlogsData?.data?.map((blog) => (
                <div key={blog.id}>
                  <BlogCard blog={blog} />
                </div>
              ))}
            </div>
          ) : (
            <div className='bg-white rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
              <p>No blog!</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AllBlogs;
