import React from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as Group12937 } from '../../../assets/images/Group12937.svg';
import { ReactComponent as Group12948 } from '../../../assets/images/Group12948.svg';
import { ReactComponent as Group12947 } from '../../../assets/images/Group12947.svg';
import { ReactComponent as Group12963 } from '../../../assets/images/Group12963.svg';
import { ReactComponent as Group12962 } from '../../../assets/images/Group12962.svg';
import { ReactComponent as Group13001 } from '../../../assets/images/Group13001.svg';

const Info = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next');

  return (
    <div className='pt-32 pb-0'>
      <div className='text-center text-2xl md:text-3xl relative bottom-20'>
        <h3 className='font-bold text-midnightBlue'>
          <span className='text-rose'>{currentLanguageCode === 'ar' ? '' : 'Holistic'}</span> {t('holistic_approach_to_hiring')}
        </h3>
      </div>
      <div className='flex justify-center w-11/12 lg:w-10/12 mx-auto px-6 md:px-4 pb-10'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-24 md:gap-20'>
          <div className='flex h-60 flex-col justify-between'>
            <div className='flex justify-start items-center h-28'>
              <Group12937 />
            </div>
            <h3 className='mt-7 md:text-laptop laptop:text-xl text-2xl text-midnightBlue'>
              {t('homepage_info_h3')}{' '}
              {currentLanguageCode === 'ar' ? (
                ''
              ) : (
                <span className='text-rose'>Collaboration</span>
              )}
            </h3>

            <p className='text-xl laptop:text-xl  md:text-md mt-2 text-greyLight h-11'>
              {t('homepage_info_p3')}
            </p>
          </div>
          <div className='flex h-60 flex-col justify-between'>
            <div className='flex justify-start items-center h-28'>
              <Group12948 />
            </div>
            <h3 className='mt-7 md:text-laptop laptop:text-xl text-2xl text-midnightBlue'>
              {t('homepage_info_h2')}{' '}
              {currentLanguageCode === 'ar' ? (
                ''
              ) : (
                <span className='text-rose'>Assessments</span>
              )}
            </h3>
            <p className='text-xl laptop:text-xl  md:text-md mt-2 text-greyLight h-11'>
              {t('homepage_info_p2')}
            </p>
          </div>
          <div className='flex h-60 flex-col justify-between'>
            <div className='flex justify-start items-center h-28'>
              <Group12947 />
            </div>
            <h3 className='mt-7 md:text-laptop laptop:text-xl text-2xl text-midnightBlue'>
              {t('homepage_info_h1')}{' '}
              {currentLanguageCode === 'ar' ? (
                ''
              ) : (
                <span className='text-rose'>Anti-cheating </span>
              )}
              {currentLanguageCode === 'ar' ? '' : 'monitor'}
            </h3>
            <p className='text-xl laptop:text-xl  md:text-md mt-2 text-greyLight h-11'>
              {t('homepage_info_p1')}
            </p>
          </div>
          <div className='flex h-60 flex-col justify-between'>
            <div className='flex justify-start items-center h-28'>
              <Group12963 />
            </div>
            <h3 className='mt-7 md:text-laptop laptop:text-xl text-2xl text-midnightBlue'>
              {t('homepage_info_h6')}{' '}
              {currentLanguageCode === 'ar' ? (
                ''
              ) : (
                <span className='text-rose'>Video Interviews</span>
              )}
            </h3>
            <p className='text-xl laptop:text-xl  md:text-md mt-2 text-greyLight h-11'>
              {t('homepage_info_p6')}
            </p>
          </div>
          <div className='flex h-60 flex-col justify-between'>
            <div className='flex justify-start items-center h-28'>
              <Group12962 />
            </div>
            <h3 className='mt-7 md:text-laptop laptop:text-xl text-2xl text-midnightBlue'>
              {t('homepage_info_h5')}{' '}
              {currentLanguageCode === 'ar' ? (
                ''
              ) : (
                <span className='text-rose'>Analytics</span>
              )}
            </h3>
            <p className='text-xl laptop:text-xl  md:text-md mt-2 text-greyLight h-11'>
              {t('homepage_info_p5')}
            </p>
          </div>
          <div className='flex h-60 flex-col justify-between'>
            <div className='flex justify-start items-center h-28'>
              <Group13001 />
            </div>
            <h3 className='mt-7 md:text-laptop laptop:text-xl text-2xl text-midnightBlue'>
              {t('homepage_info_h4')}{' '}
              {currentLanguageCode === 'ar' ? (
                ''
              ) : (
                <span className='text-rose'>CV database</span>
              )}
            </h3>
            <p className='text-xl laptop:text-xl  md:text-md mt-2 text-greyLight h-11'>
              {t('homepage_info_p4')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
